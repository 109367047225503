exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-template-article-detail-js": () => import("./../../../src/pages/template--article-detail.js" /* webpackChunkName: "component---src-pages-template-article-detail-js" */),
  "component---src-pages-template-basic-js": () => import("./../../../src/pages/template--basic.js" /* webpackChunkName: "component---src-pages-template-basic-js" */),
  "component---src-pages-template-inspiration-overview-js": () => import("./../../../src/pages/template--inspiration-overview.js" /* webpackChunkName: "component---src-pages-template-inspiration-overview-js" */),
  "component---src-pages-template-news-overview-js": () => import("./../../../src/pages/template--news-overview.js" /* webpackChunkName: "component---src-pages-template-news-overview-js" */),
  "component---src-pages-template-newsletter-js": () => import("./../../../src/pages/template--newsletter.js" /* webpackChunkName: "component---src-pages-template-newsletter-js" */)
}

